import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useHistory} from "react-router-dom";
import {IconButton, Paper} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[8],
            padding: theme.spacing(2, 4, 3),
            outline: "none"
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "center"
        },
        button: {
            border: '1px solid black',
            color: 'black',
            backgroundColor: 'white',
            margin: '20px auto',
            minWidth: '100px'
        },
        closeButton: {
            position: "absolute",
            top: 90,
            right: 0
        }
    }),
);

export default function ConfirmationModal() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const history = useHistory();
    const handleBack = () => {
        localStorage.setItem('currentPage', '/topics');
        history.push('/topics');
    };

    return (
        <React.Fragment>
            <IconButton className={classes.closeButton} onClick={handleOpen}>
                <Close style={{fontSize: '1.6em'}}/>
            </IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Paper className={classes.paper}>
                        <h3 id="transition-modal-title">Quiz wirklich verlassen?</h3>
                        <div className={classes.buttonWrapper}>
                            <Button onClick={handleBack} variant="contained" className={classes.button}>
                                Ja
                            </Button>
                            <Button onClick={handleClose} variant="contained" className={classes.button}>
                                Nein
                            </Button>
                        </div>
                    </Paper>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}