import React from 'react';
import {useHistory} from "react-router-dom";
import {
    Card,
    CardActionArea,
    CardContent,
    CircularProgress,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {useData} from "./layout";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    wrapper: {
        padding: '0px'
    },
    loadingScreen: {
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const Difficulties = () => {
    const classes = useStyles();
    const {difficulties, changeDifficulty} = useData();

    const history = useHistory();
    const chooseDifficulty = (value: number) => {
        setTimeout(function () {
            changeDifficulty(value);
            localStorage.setItem('currentDifficulty', String(value));
            localStorage.setItem('currentPage', '/topics');
            history.push('/topics')
        }, 400);
    };

    return (
        <div>
            <h3>Diese Schwierigkeitsstufen sind spielbar:</h3>
            {difficulties ? (
                <div className={classes.wrapper}>
                    <Grid container spacing={4}>
                        {
                            difficulties.map((difficulty, index) =>
                                <Grid item xs={12} sm={6} key={index}>
                                    <Card>
                                        <CardActionArea onClick={
                                            () => chooseDifficulty(difficulty.uid)
                                        }>
                                            <CardContent>
                                                <Typography align={"center"} variant="h5" component="h2">
                                                    {difficulty.title}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )}
                    </Grid>
                </div>
            ) : (
                <div className={classes.loadingScreen}>
                    <CircularProgress size={80} color="primary"/>
                </div>
            )}
        </div>
    );
};

export default Difficulties;