import React, {useLayoutEffect} from 'react';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {useData} from "./layout";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    buttonWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: spacing(4),
    },
    button: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white',
        margin: '20px auto',
    },
    wrapper: {
        padding: '0px'
    },
    media: {
        height: 200,
    },
    loadingScreen: {
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    }
}));

const Topics = () => {
    const classes = useStyles();
    const history = useHistory();
    const {quizResponse, changeQuiz, setIsNotAllowed, selectedDifficulty} = useData();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const goBack = () => {
        localStorage.setItem('currentPage', '/difficulties');
        history.push('/difficulties');
    };
    const chooseQuiz = (value: number, isNotAllowed: boolean) => {
        setTimeout(function () {
            if (isNotAllowed) {
                setIsNotAllowed(true);
            } else {
                setIsNotAllowed(false);
            }
            changeQuiz(value);
            localStorage.setItem('currentPage', '/topic');
            localStorage.setItem('currentQuiz', String(value));
            history.push('/topic');
        }, (400));
    };
    const quizzes = quizResponse?.quizzes;
    const notAllowedQuizzes = quizResponse?.notAllowedQuizzes;
    const isAdult = selectedDifficulty?.title === "Erwachsene";

    return (
        <div>
            {(quizzes || notAllowedQuizzes) ? (
                <div>
                    <div>
                        {(isAdult) ? (
                            <h3>Wählen Sie ein Quiz aus:</h3>
                        ) : (
                            <h3>Wähle ein Quiz aus:</h3>
                        )}
                    </div>
                    {quizzes ? (
                        <div className={classes.wrapper}>
                            <Grid container spacing={4}>
                                {
                                    quizzes.map((quiz, index) =>
                                        <Grid item xs={12} sm={6} key={index}>
                                            <Card>
                                                <CardActionArea
                                                    onClick={() => chooseQuiz(quiz.uid, false)}>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={quiz.topic.image?.processedImageUrl}
                                                        title={quiz.topic.title}
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {quiz.topic.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            {quiz.topic.text}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )}
                            </Grid>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {notAllowedQuizzes ? (
                        <div className={classes.wrapper}>
                            <Grid container spacing={4}>
                                {
                                    notAllowedQuizzes.map((quiz, index) =>
                                        <Grid item xs={12} sm={6} key={index}>
                                            <Card key={index} className={"not-allowed-topic"}>
                                                <CardActionArea
                                                    onClick={() => chooseQuiz(quiz.uid, true)}>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={quiz.topic.image?.processedImageUrl}
                                                        title={quiz.topic.title}
                                                    >
                                                        <p className={"not-allowed-topic-image-label"}>Im Museum spielbar</p>
                                                    </CardMedia>
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {quiz.topic.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            {quiz.topic.text}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )}
                            </Grid>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className={classes.buttonWrapper}>
                        <Button onClick={() => goBack()} variant="contained" className={classes.button}>Zurück</Button>
                    </div>
                </div>
            ) : (
                <div className={classes.loadingScreen}>
                    <CircularProgress size={80} color="primary"/>
                </div>
            )}
        </div>
    );
};

export default Topics;