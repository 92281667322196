import React, {useEffect, useState, useContext} from 'react';
import {makeStyles, Paper, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {Route, Switch, useHistory, useLocation, Redirect} from "react-router-dom";
import PageNotFound from "./page-not-found";
import Quiz from "./quiz";
import Difficulties from "./difficulties";
import Navigation from "./components/navigation";
import Topic from "./components/topic";
import Page from "./page";
import Topics from "./topics";
import {Difficulty, NotAllowedQuizObject, QuizObject, QuestionObject} from "../types";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    root: {
        flex: 1,
        backgroundColor: palette.background.paper,
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column'
    },
    content: {
        flex: 1,
        padding: spacing(2, 2),
        maxWidth: '768px',
        width: '100%',
        margin: '0 auto',
        boxShadow: 'none'
    }
}), {name: 'Layout'});

type QuizResponse = {
    quizzes?: Array<QuizObject>,
    notAllowedQuizzes?: Array<NotAllowedQuizObject>
}

type DataContextValues = {
    difficulties?: Array<Difficulty>,
    changeDifficulty: (id: number) => void,
    selectedDifficulty?: Difficulty,
    quizResponse?: QuizResponse,
    changeQuiz: (id: number) => void,
    selectedQuiz?: QuizObject | NotAllowedQuizObject,
    currentQuestion?: QuestionObject,
    setCurrentQuestion: (currentQuestion: QuestionObject) => void,
    isNotAllowed: (boolean),
    setIsNotAllowed: (isNotAllowed: boolean) => void,
    quizzes?: Array<QuizObject | NotAllowedQuizObject>,
    openExplanation: (boolean),
    setOpenExplanation: (openExplanation: boolean) => void
}

const DataContext = React.createContext<DataContextValues>({
    difficulties: undefined,
    changeDifficulty: () => null,
    selectedDifficulty: undefined,
    quizResponse: undefined,
    changeQuiz: () => null,
    selectedQuiz: undefined,
    setCurrentQuestion: () => null,
    currentQuestion: undefined,
    isNotAllowed: false,
    setIsNotAllowed: () => false,
    openExplanation: false,
    setOpenExplanation: () => false
});

const DataProvider: React.FC = ({children}) => {
    const [difficultyList, setDifficultyList] = useState<Array<Difficulty>>([]);
    const [quizResponse, setQuizResponse] = useState<QuizResponse | undefined>();
    const [selectedDifficulty, setSelectedDifficulty] = useState<Difficulty | undefined>();
    const [selectedQuiz, setSelectedQuiz] = useState<QuizObject | NotAllowedQuizObject | undefined>();
    const [isNotAllowed, setIsNotAllowed] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<QuestionObject | undefined>();
    const [openExplanation, setOpenExplanation] = React.useState(false);

    const loadDifficulties = async () => {
        const response = await fetch('/quiz/difficulties', {cache: "no-store"});
        const json = await response.json();
        setDifficultyList(json);
    };
    useEffect(() => {
        loadDifficulties();
        const currentDifficulty = localStorage.getItem('currentDifficulty');
        const currentQuiz = localStorage.getItem('currentQuiz');
        if (currentDifficulty) {
            loadQuizData(Number(currentDifficulty)).then( response => {
                if (currentQuiz) {
                    const quizAllowed = response?.quizzes?.find((q: QuizObject) => q.uid === Number(currentQuiz));
                    const quizNotAllowed = response?.notAllowedQuizzes?.find((q: NotAllowedQuizObject) => q.uid === Number(currentQuiz));
                    const quiz = quizAllowed ?? quizNotAllowed;
                    if (quiz) {
                        if (quizNotAllowed) {
                            setIsNotAllowed(true);
                        } else {
                            setIsNotAllowed(false);
                        }
                        setSelectedQuiz(quiz);
                    }
                }
            });
        }
    }, []);

    const changeDifficulty = (id: number) => {
        const difficulty = difficultyList.find((d: Difficulty) => d.uid === id);
        if (difficulty) {
            setSelectedDifficulty(difficulty);
        }
    };

    const changeQuiz = (id: number) => {
        const quiz = quizResponse?.quizzes?.find((q: QuizObject) => q.uid === id) ?? quizResponse?.notAllowedQuizzes?.find((q: NotAllowedQuizObject) => q.uid === id);
        if (quiz) {
            setSelectedQuiz(quiz);
        }
    };

    const loadQuizData = async (id: number) => {
        setQuizResponse(undefined);
        const response = await fetch('/quiz/listByDifficulty/' + id, {cache: "no-store"});
        const json = await response.json();
        setQuizResponse(json);
        return json;
    };

    useEffect(() => {
        if (selectedDifficulty === undefined || selectedDifficulty === null) return;
        loadQuizData(selectedDifficulty.uid);
    }, [selectedDifficulty]);

    return (
        <DataContext.Provider value={{
            difficulties: difficultyList,
            changeDifficulty,
            selectedDifficulty,
            quizResponse,
            changeQuiz,
            selectedQuiz,
            setIsNotAllowed,
            isNotAllowed,
            setCurrentQuestion,
            currentQuestion,
            openExplanation,
            setOpenExplanation
        }}>
            {children}
        </DataContext.Provider>
    )
};

export function useData() {
    return useContext(DataContext);
}

const Layout = () => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <DataProvider>
            <Paper square elevation={0} className={classes.root}>
                {
                    <React.Fragment>
                        <Navigation/>
                        <Paper className={classes.content}>
                            <Switch>
                                <Route path={'/'} exact component={Page}/>
                                <Route path={'/topic'} exact render={() => <Topic/>}/>
                                <Route path={'/quiz'} exact render={() => <Quiz/>}/>
                                <Route path={'/difficulties'} exact render={() => <Difficulties/>}/>
                                <Route path={'/topics'} exact render={() => <Topics/>}/>
                                <Route path={'/:pid'} exact component={Page}/>
                                <Route component={PageNotFound}/>
                            </Switch>
                        </Paper>
                    </React.Fragment>
                }
            </Paper>
        </DataProvider>
    );
};

export default Layout;