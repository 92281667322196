import React from 'react';
import {Backdrop, Fade, makeStyles, Modal, Paper, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from '@material-ui/core/Button';
import {Media} from "../../types";
import Iframe from "react-iframe";
import theme from '../../theme/theme';
import {processVideoUrl} from "../functions";
import { useData } from '../layout';

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    centerContent: {
        display: "flex",
        justifyContent: "center"
    },
    centerText: {
        display: "flex",
        justifyContent: "center"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white'
    },
    videoOuter: {
        position: "relative",
        paddingBottom: "56.25%",
        paddingTop: "30px",
        height: "0",
        overflow: "hidden"
    },
    videoInner: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        border: "none"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[8],
        padding: theme.spacing(2, 4, 3),
        outline: "none"
    },
}));

type ExplanationProps = {
    title: string | undefined,
    media: Media | null,
    text: string | undefined
}

const Explanation: React.FunctionComponent<ExplanationProps> = ({title,media,text}) => {
    const classes = useStyles();
    const {setOpenExplanation, openExplanation} = useData();

    const handleCloseExplanation = () => {
        setOpenExplanation(false);
    };

    return (
        <div>
            <Modal
                style={{padding: "0 16px"}}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openExplanation}
                onClose={handleCloseExplanation}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openExplanation}>
                    <Paper className={classes.paper}>
                        <h3 id="transition-modal-title">{title}</h3>
                        <div>
                            {media &&
                            <div>
                                {media.extension === "youtube" || media.extension === "vimeo" ? (
                                    <div className={classes.videoOuter}>
                                        <Iframe url={processVideoUrl(media)}
                                                className={classes.videoInner}
                                                allowFullScreen
                                        />
                                    </div>
                                ) : (
                                    <div className={"image-caption-wrapper"}>
                                        <img style={{width: "100%", display: "block"}} src={media.processedImageUrl}
                                             alt={media.alternative}/>
                                        <span className="image-copyright">
                                            <p>{media.description}</p>
                                            <p>{media.copyright}</p>
                                        </span>
                                    </div>
                                )}
                            </div>
                            }
                        </div>
                        {text ? <p dangerouslySetInnerHTML={{__html: text}}/> : <div></div>}
                        <br></br>
                        <div className={classes.buttonWrapper}>
                            <Button onClick={handleCloseExplanation} variant="contained" className={classes.button}>
                                Schließen
                            </Button>
                        </div>
                    </Paper>
                </Fade>
            </Modal>
        </div>
    );
};

export default Explanation;
