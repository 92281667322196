import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {AppBar, IconButton, Toolbar} from "@material-ui/core";
import {Home, Menu, QuestionAnswer} from "@material-ui/icons";
import {useHistory, useLocation} from "react-router-dom";
import logo from './../../images/logo_transparent_320px.png';

const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: 'auto',
    },
    startPageNav: {
        boxShadow: 'none'
    },
    nav: {

    }
});

export default function Navigation() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [menuData, setMenuData] = useState(null);


    let location = useLocation();
    let urlArray = location.pathname.split('/');
    let slug = urlArray[urlArray.length-1];

    let onStartPage: boolean = false;
    if (slug === "") {
        onStartPage = true;
    }

    type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
    const toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const history = useHistory();
    const goToPage = (page: string) => {
        localStorage.setItem('currentPage', page);
        history.push(page);
    };

    const urlMenu: string = "?type=99902";
    useEffect(() => {
        // @ts-ignore
        const loadData = async urlMenu => {
            const response = await fetch(urlMenu, {cache: "no-store"});
            const json = await response.json();
            setMenuData(json);
        };
        loadData(urlMenu);
    }, [urlMenu]);

    // @ts-ignore
    const pages = menuData?.navigation[0]?.children;

    const pagesMain = [
        {
            title: 'Home',
            link: '/'
        },
        {
            title: 'Quizze',
            link: '/difficulties'
        }
    ];

    // @ts-ignore
    const mainMenu = (side: DrawerSide) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                {pagesMain.map((page, index) => (
                    <ListItem onClick={() => {goToPage(page.link)}} button key={page.link}>
                        <ListItemIcon>{index % 2 === 0 ? <Home /> : <QuestionAnswer />}</ListItemIcon>
                        <ListItemText primary={page.title} />
                    </ListItem>
                ))}
            </List>
            {pages && (
                <React.Fragment>
                    <Divider />
                    <List>
                        {
                            // @ts-ignore
                            pages.map((page, index) => (
                                // @ts-ignore
                            <ListItem onClick={() => {goToPage(page.link)}} button key={page.link}>
                                <ListItemText primary={
                                    // @ts-ignore
                                    page.title
                                } />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            )}
        </div>
    );

    return (
        <AppBar className={onStartPage ? classes.startPageNav : ""} position={"static"} style={{backgroundColor: "transparent"}}>
            <Toolbar>
                <IconButton size={"medium"} style={{color: "black"}} onClick={toggleDrawer('left', true)}><Menu style={{fontSize: '1.6em'}} /></IconButton>
                <Drawer className={"app-nav"} open={state.left} onClose={toggleDrawer('left', false)}>
                    {mainMenu('left')}
                </Drawer>
                <img style={{"width": "80px", "padding":"5px", "marginLeft": "auto"}} src={logo} alt={"Logo"}/>
            </Toolbar>
        </AppBar>
    );
}