import React, {useState} from 'react';
import {Button, Checkbox, FormControl, FormHelperText, Input, InputLabel, makeStyles, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {Field, Form} from "react-final-form";

const useStyles = makeStyles(({}: Theme) => createStyles({
    buttonWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white',
        margin: '20px auto',
        minWidth: '100px'
    },
    centered: {
        textAlign: "center"
    }
}));

// @ts-ignore
const InputField = ({input, label, meta}) => {
    return (
        <FormControl fullWidth {...input}>
            <InputLabel htmlFor={"input-" + input.name}>{label}</InputLabel>
            <Input {...input} fullWidth id={"input-" + input.name}/>
            {meta.error && meta.touched &&
            <FormHelperText style={{color: "#bf0000"}} id={"helper-" + input.name}>{meta.error}</FormHelperText>}
        </FormControl>
    )
};

// @ts-ignore
const CheckboxField = ({input, label, meta}) => {
    return (
        <FormControl className={"check-box"} {...input}>
            <InputLabel htmlFor={"input-" + input.name}>{label}</InputLabel>
            <Checkbox {...input} id={"input-" + input.name}/>
            {meta.error && meta.touched &&
            <FormHelperText style={{color: "#bf0000"}} id={"helper-" + input.name}>{meta.error}</FormHelperText>}
        </FormControl>
    )
};

type NewsletterProps = {}

// @ts-ignore
const required = value => (value ? undefined : 'Pflichtfeld');

const Newsletter = () => {
    const classes = useStyles();

    const [sent, setSent] = useState(false);

    // @ts-ignore
    const onSubmit = (values) => {
        setSent(true);
        const url = "/quiz/newsletter";
        const formData = new FormData();
        formData.append('tx_moylandquiz_quiz_api[email]',values.email);
        formData.append('tx_moylandquiz_quiz_api[firstname]',values.firstname);
        formData.append('tx_moylandquiz_quiz_api[lastname]',values.lastname);
        formData.append('tx_moylandquiz_quiz_api[age]',values.age);
        formData.append('tx_moylandquiz_quiz_api[checkbox]',values.checkbox);
        fetch(url, {
            method: 'POST',
            body: formData
        }).then(result => {
        }).catch((e) => {
        });
    };

    return (
        <div>
            {!sent ? (
                <React.Fragment>
                    <h3 className={classes.centered}>
                        Museumsnews kostenfrei abonnieren (jederzeit kündbar)
                    </h3>
                    <Form
                        onSubmit={onSubmit}
                        render={({handleSubmit, submitting, values}) => (
                            <form onSubmit={handleSubmit} method={"post"}>
                                {
                                    // @ts-ignore
                                    <Field component={InputField}
                                           label={"E-Mail Adresse *"}
                                           name={"email"}
                                           type={"email"}
                                           placeholder={""}
                                           validate={required}
                                           fullWidth
                                    />
                                }
                                <br/><br/>
                                {
                                    // @ts-ignore
                                    <Field component={InputField}
                                           label={"Vorname"}
                                           name={"firstname"}
                                           type={"text"}
                                           placeholder={"Vorname"}
                                    />
                                }
                                <br/><br/>
                                {
                                    // @ts-ignore
                                    <Field component={InputField}
                                           label={"Nachname"}
                                           name={"lastname"}
                                           type={"text"}
                                           placeholder={"Nachname"}
                                    />
                                }
                                <br/><br/>
                                {
                                    // @ts-ignore
                                    <Field component={InputField}
                                           label={"Alter"}
                                           name={"age"}
                                           type={"number"}
                                           placeholder={""}
                                    />
                                }
                                <br/><br/>
                                {
                                    // @ts-ignore
                                    <Field component={CheckboxField}
                                           label={"Beim Abschicken des Formulars erkläre ich mich mit den Datenschutzbedingungen einverstanden: *"}
                                           name={"checkbox"}
                                           type={"checkbox"}
                                           placeholder={""}
                                           validate={required}
                                    />
                                }
                                <a href="https://moyland.de/information/datenschutz/" style={{padding: "0 0 0 46px"}}>Datenschutzbedingungen</a>
                                <br/><br/>
                                <div className={classes.buttonWrapper}>
                                    <Button disabled={submitting || !values.email || !values.checkbox} onClick={handleSubmit}
                                            variant="contained" className={classes.button}>
                                        Abonnieren
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </React.Fragment>
            ) : (
                <div>
                    Danke für die Anmeldung zum Newsletter!
                </div>
            )}
        </div>
    );
};

export default Newsletter;
