import React, {useLayoutEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Answer from "./answer";
import {makeStyles, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Iframe from 'react-iframe';
import {processVideoUrl} from "../functions";
import {AnswerObject, AnswerStatus, ExplanationObject, Media} from "../../types";
import theme from '../../theme/theme';
import Explanation from "./explanation";
import {useData} from "../layout";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    centerContent: {
        display: "flex",
        justifyContent: "center"
    },
    centerText: {
        display: "flex",
        justifyContent: "center"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white'
    },
    videoOuter: {
        position: "relative",
        paddingBottom: "56.25%",
        paddingTop: "30px",
        height: "0",
        overflow: "hidden"
    },
    videoInner: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        border: "none"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[8],
        padding: theme.spacing(2, 4, 3),
        outline: "none"
    },
}));

type QuestionProps = {
    question: string,
    answers: AnswerObject[],
    explanation: ExplanationObject | null,
    media: Media | null,
    handleClick: ((id: number, correct: boolean) => void)
}

const Question: React.FunctionComponent<QuestionProps> = ({question, answers, explanation, media, handleClick}) => {
    const classes = useStyles();
    const [done, setDone] = useState(false);
    const [selection, setSelection] = useState(0);
    const [result, setResult] = useState(false);

    useLayoutEffect(() => {
        if (!done) {
            window.scrollTo(0, 0);
        }
    }, [done]);

    const {selectedQuiz, setOpenExplanation, openExplanation} = useData();

    const handleOpenExplanation = () => {
        setOpenExplanation(true);
    };


    const handleAnswer = (id: number, correct: boolean) => {
        setSelection(id);
        setResult(correct);
        setDone(true);
    };

    const handleNext = () => {
        setDone(false);
        handleClick(selection, result);
    };

    return (
        <div>
            <div>
                {media &&
                <div>
                    {media.extension === "youtube" || media.extension === "vimeo" ? (
                        <div className={classes.videoOuter}>
                            <Iframe url={processVideoUrl(media)}
                                    className={classes.videoInner}
                                    allowFullScreen
                            />
                        </div>
                    ) : (
                        <div className={"image-caption-wrapper"}>
                            <img style={{width: "100%", display: "block"}} src={media.processedImageUrl}
                                 alt={media.alternative}/>
                            <span className="image-copyright">
                                     <p>{media.description}</p>
                                    <p>{media.copyright}</p>
                                </span>
                        </div>
                    )}
                </div>
                }
            </div>
            <h2 className={classes.centerText} dangerouslySetInnerHTML={{__html: question}}/>
            {!done ? (
                <div>
                    {answers.map((answer, index) =>
                        <Answer disabled={done} key={index} answer={answer} status={AnswerStatus.normal} number={index}
                                handleClick={handleAnswer}/>
                    )}
                </div>
            ) : (
                <div>
                    {answers.map((answer, index) =>
                        <div key={index}>
                            {(answer.uid === selection && answer.correct) &&
                            <Answer disabled={done} key={index} answer={answer} status={AnswerStatus.correct}
                                    number={index} handleClick={handleAnswer}/>}
                            {(answer.uid === selection && !answer.correct) &&
                            <Answer disabled={done} key={index} answer={answer} status={AnswerStatus.wrong}
                                    number={index} handleClick={handleAnswer}/>}
                            {(answer.uid !== selection && answer.correct) &&
                            <Answer disabled={done} key={index} answer={answer} status={AnswerStatus.info}
                                    number={index} handleClick={handleAnswer}/>}
                            {(answer.uid !== selection && !answer.correct) &&
                            <Answer disabled={done} key={index} answer={answer} status={AnswerStatus.normal}
                                    number={index} handleClick={handleAnswer}/>}
                        </div>
                    )}
                </div>
            )}
            <br/>
            <div className={classes.buttonWrapper}>
                {done && explanation ? <Button onClick={handleOpenExplanation} variant="contained"
                                               className={classes.button}>Erklärung</Button> : <div></div>}
                {openExplanation && explanation ?
                    <Explanation title={explanation.title} media={explanation.media} text={explanation.text}/> :
                    <div></div>}
                <Button disabled={!done} onClick={handleNext} variant="contained" className={classes.button}>
                    Weiter
                </Button>
            </div>
        </div>
    );
};

export default Question;
