import React, {useEffect, useLayoutEffect, useState, Fragment} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Question from "./components/question";
import QuizResult from "./components/quiz-result";
import {FiberManualRecord, FiberManualRecordOutlined} from '@material-ui/icons';
import ConfirmationModal from "./components/confirmation-modal";
import {useData} from "./layout";
import {QuizObject} from "../types";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    topicTitle: {
        display: "flex",
        justifyContent: "center",
        marginLeft: "40px",
        marginRight: "40px"
    },
    centerContent: {
        display: "flex",
        justifyContent: "center"
    },
    centerText: {
        display: "flex",
        justifyContent: "center"
    },
    buttonClose: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const Quiz = () => {
    const classes = useStyles();
    const [score, setScore] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [choices, setChoices] = useState([0]);
    const [itHasBegun, setItHasBegun] = useState(false);
    const [itHasEnded, setItHasEnded] = useState(false);

    const {selectedQuiz} = useData();

    useLayoutEffect(() => {
        if (currentPage === 0) {
            window.scrollTo(0, 0);
        }
    }, [currentPage]);

    const questions = (selectedQuiz as QuizObject)?.questions;

    useEffect(() => {
        if (!itHasBegun && selectedQuiz) {
            setItHasBegun(true);
        }
    }, [itHasBegun, selectedQuiz]);

    // useEffect(() => {
    //     // get current progress after reopening app?
    // }, []);

    if (!(currentPage < questions?.length) && !itHasEnded) {
        setItHasEnded(true);
        const formData = new FormData();

        if (selectedQuiz !== undefined) {
            formData.append('tx_moylandquiz_quiz_api[quizId]', selectedQuiz.uid.toString());
        }
        const url = "/quiz/submitQuiz";
        fetch(url, {
            method: 'POST',
            credentials: "include",
            body: formData
        }).then(result => {
        }).catch((e) => {
        });
    }

    const progress: boolean[] = [];
    (questions !== undefined && questions) && questions.forEach((question, index) => {
        let correctAnswer = question.answers.find(answer => answer.correct);
        if (choices[index] && correctAnswer !== undefined)
            progress[index] = choices[index] === correctAnswer.uid;
    });

    const handleClick = (id: number, correct: boolean) => {
        let newChoices = choices;
        // @ts-ignore
        newChoices[currentPage] = id;
        setChoices(newChoices);
        setCurrentPage(currentPage + 1);
        if (correct) {
            setScore(score + 1);
        }
    };

    return (
        <div>
            <div className={classes.buttonClose}>
                <ConfirmationModal/>
            </div>
            {selectedQuiz ? (
                <div>
                    <h4 className={classes.topicTitle}>{selectedQuiz.topic.title}</h4>
                    <hr/>
                    <br/>
                    {currentPage < questions.length ? (
                        <Fragment>
                            <h3 className={classes.centerText}>Frage {currentPage + 1}/{questions.length}</h3>
                            <Question question={questions[currentPage].text}
                                      answers={questions[currentPage].answers}
                                      explanation={questions[currentPage].explanation}
                                      media={questions[currentPage].media}
                                      handleClick={handleClick}/>
                            <br/>
                            <div className={classes.centerContent}>
                                {questions.map((question, index) =>
                                    progress[index] !== undefined ? (
                                        progress[index] ? (
                                            <FiberManualRecord key={index} style={{color: "green"}}/>
                                        ) : (
                                            <FiberManualRecord key={index} style={{color: "red"}}/>
                                        )
                                    ) : (
                                        <FiberManualRecordOutlined key={index}/>
                                    )
                                )}
                            </div>
                        </Fragment>
                    ) : (
                        <QuizResult quiz={selectedQuiz as QuizObject} choices={choices} score={score}/>
                    )}

                </div>
            ) : (
                <div></div>
            )
            }
        </div>
    );

};

export default Quiz;