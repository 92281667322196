import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
// @ts-ignore
import {Textfit} from 'react-textfit';
import {AnswerObject, AnswerStatus} from "../../types";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    answerNormal: {
        backgroundColor: "white",
        border: "1px solid black",
        height: "80px",
        color: "black",
        borderRadius: "40px",
        overflow: "hidden",
        padding: 0
    },
    answerCorrect: {
        border: "1px solid black",
        height: "80px",
        color: "black",
        background: 'linear-gradient(to bottom, #71d467, #00bf00)',
        borderRadius: "40px",
        overflow: "hidden",
        padding: 0
    },
    answerWrong: {
        border: "1px solid black",
        height: "80px",
        color: "black",
        background: 'linear-gradient(to bottom, #fa3b1e, #bf0000)',
        borderRadius: "40px",
        overflow: "hidden",
        padding: 0
    },
    answerNumber: {
        position: "absolute",
        left: "0",
        backgroundColor: "white",
        borderRight: "2px solid black",
        color: "black",
        width: "60px",
        height: "100%",
        fontSize: "28px",
        lineHeight: "80px",
        fontWeight: 600,
        paddingLeft: "5px"
    },
    answerIcon: {
        position: "absolute",
        right: "15px",
        fontSize: "50px",
        color: "white"
    },
    answerText: {
         width: "100%",
        padding: "7px 20px 7px 70px",
        overflow: "hidden",
        lineHeight: 1.4,
        fontFamily: "'Krub', 'Roboto Light', sans-serif",
        maxHeight: 78
    }
}));

type AnswerProps = {
    answer: AnswerObject,
    disabled: boolean,
    status: AnswerStatus,
    number: number,
    handleClick: ((id: number, correct: boolean) => void)
}
const Answer: React.FunctionComponent<AnswerProps> = ({answer, disabled, status, number, handleClick}) => {
    const classes = useStyles();
    let title = "";
    switch(number) {
        case 0: title = "A";
            break;
        case 1: title = "B";
            break;
        case 2: title = "C";
            break;
        case 3: title = "D";
            break;
    }

    return (
        <div>
            {!disabled ? (
                status === AnswerStatus.normal && <Button fullWidth={true} className={classes.answerNormal} onClick={() => handleClick(answer.uid, answer.correct)} variant="contained" color="primary">
                    <span className={classes.answerNumber}>{title}</span>
                    <div className={classes.answerText}><Textfit mode="multi" min={12} max={24}>{answer.text}</Textfit></div>
                </Button>
            ) : (
                <React.Fragment>
                    {status === AnswerStatus.normal && <Button fullWidth={true} style={{pointerEvents: "none"}} className={classes.answerNormal} onClick={() => handleClick(answer.uid, answer.correct)} variant="contained" color="primary">
                        <span className={classes.answerNumber}>{title}</span>
                        <div className={classes.answerText}><Textfit mode="multi" min={12} max={24}>{answer.text}</Textfit></div>
                    </Button>}
                    {status === AnswerStatus.correct && <Button fullWidth={true} style={{pointerEvents: "none"}} className={classes.answerCorrect} onClick={() => handleClick(answer.uid, answer.correct)} variant="contained" color="primary">
                        <span className={classes.answerNumber}>{title}</span>
                        <div className={classes.answerText}><Textfit mode="multi" min={12} max={24}>{answer.text}</Textfit></div>
                    </Button>}
                    {status === AnswerStatus.wrong && <Button fullWidth={true} style={{pointerEvents: "none"}} className={classes.answerWrong} onClick={() => handleClick(answer.uid, answer.correct)} variant="contained" color="primary">
                        <span className={classes.answerNumber}>{title}</span>
                        <div className={classes.answerText}><Textfit mode="multi" min={12} max={24}>{answer.text}</Textfit></div>
                    </Button>}
                    {status === AnswerStatus.info && <Button fullWidth={true} style={{pointerEvents: "none"}} className={classes.answerCorrect} onClick={() => handleClick(answer.uid, answer.correct)} variant="contained" color="primary">
                        <span className={classes.answerNumber}>{title}</span>
                        <div className={classes.answerText}><Textfit mode="multi" min={12} max={24}>{answer.text}</Textfit></div>
                    </Button>}
                </React.Fragment>
            )}
         <br/><br/>
        </div>
    );
};

export default Answer;
