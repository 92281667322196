import React, {useEffect, useState, useLayoutEffect} from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Card, CardContent, makeStyles, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Newsletter from "./newsletter";
import {QuestionObject, QuizObject} from "../../types";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    circularResult: {
        width: "50%"
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white',
        margin: '20px auto'
    }
}));

type QuizResultProps = {
    quiz: QuizObject,
    choices: number[],
    score: number
}

const QuizResult = ({quiz, choices, score}: QuizResultProps) => {
    const classes = useStyles();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const questions: QuestionObject[] = quiz.questions;

    const rank = (score >= quiz.requirementRankThree) ? 3 : (score >= quiz.requirementRankTwo) ? 2 : 1;
    let resultText = "";
    switch (rank) {
        case 1:
            resultText = quiz.rankOneText ? quiz.rankOneText : quiz.difficulty.rankOneTextDefault;
            break;
        case 2:
            resultText = quiz.rankTwoText ? quiz.rankTwoText : quiz.difficulty.rankTwoTextDefault;
            break;
        case 3:
            resultText = quiz.rankThreeText ? quiz.rankThreeText : quiz.difficulty.rankThreeTextDefault;
            break;
    }

    const history = useHistory();
    const handleRestart = () => {
        history.push('/difficulties/');
    };
    const handleBack = () => {
        history.push('/');
    };

    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setInterval(() => {
            setPercentage(score);
        }, 200);
    }, [percentage,score]);

    const isAdult = quiz.difficulty.title === "Erwachsene";

    return (
        <div>
            <div className={classes.centerContent}>
                <h2>{isAdult ? 'Ihr' : 'Dein'} Ergebnis</h2>
                <div className={classes.circularResult}>
                    {
                        <CircularProgressbar
                            value={percentage}
                            maxValue={questions.length}
                            text={`${score} von ${questions.length}`}
                            styles={buildStyles({
                                backgroundColor: "#e0e0e0",
                                textColor: "#000000",
                                pathColor: "#00bf00",
                                trailColor: "#e0e0e0",
                                pathTransition:
                                    percentage === 0 ? "none" : "stroke-dashoffset 1s ease 0s"
                            })}
                        />
                    }
                </div>
                <br/>
                <h3>{score} von {questions.length} richtige Antworten!</h3>
                <div>
                    <p dangerouslySetInnerHTML={{__html: resultText}}/>
                </div>
            </div>
            {isAdult && (
                <div>
                    <br/><br/>
                    <Card>
                        <CardContent>
                            <Newsletter/>
                        </CardContent>
                    </Card>
                </div>
            )}
            <br/><br/>
            <div className={classes.buttonWrapper}>
                <Button className={classes.button} onClick={handleRestart} variant="contained">
                    Nochmal versuchen
                </Button>
                <Button className={classes.button} onClick={handleBack} variant="contained">
                    Zur Startseite
                </Button>
            </div>
        </div>
    );
};

export default QuizResult;
