import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from "./theme/theme";
import {CssBaseline} from "@material-ui/core";
import Layout from "./views/layout";
import {BrowserRouter} from "react-router-dom";

const App = () => {
    return (
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <CssBaseline/>
              <Layout/>
          </ThemeProvider>
      </BrowserRouter>
  );
};

export default App;
