import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import hosts from "../constants";

type PageProps = {
    isStartPage?: boolean
}

const Page = ({isStartPage}:PageProps) => {
    window.scrollTo(0, 0);
    let location = useLocation();
    let urlArray = location.pathname.split('page/');
    let slug = urlArray[urlArray.length-1];
    const [data, setData] = useState<string>('');

    // check if browser is safari to add specific safari css class
    var ua = navigator.userAgent.toLowerCase();
    let isSafari;
    if (ua.indexOf('safari') != -1 || navigator.platform.substr(0, 2) === 'iP' || navigator.platform === 'MacIntel') {
        isSafari = ua.indexOf('chrome') <= -1;
    } else {
        isSafari = false
    }

    // @ts-ignore
    const url: string = slug + "?type=99901";

    useEffect(() => {
        // @ts-ignore
        const loadData = async url => {
            const response = await fetch(url, {
                mode: 'no-cors',
                headers: {
                    "Content-Type": "text/plain"
                },
                cache: "no-store"
            });
            const html: string = await response.text();
            setData(html);
        };
        loadData(url);
    }, [url]);

    const history = useHistory();
    const goToPage = (slug: string) => {
        localStorage.setItem('currentPage', slug);
        history.push(slug);
    };

    // @ts-ignore
    const clickHandler = (e) => {
        const el = e.target.closest("A");
        if (el && e.currentTarget.contains(el)) {
            let urlArray = el.href.split('/');

            for (let i = 0; i < urlArray.length-1; i++) {
                if (hosts.indexOf(urlArray[i]) >= 0) {
                    e.preventDefault();
                    let slug = urlArray[urlArray.length-1];
                    goToPage(slug);
                }
            }

            // prevent default for mailto links
            let href= el.href;
            if (href.includes("mailto:")) {
                e.preventDefault();
                window.open(href, '_self');
            }
        }
    };

    return (
        <div>
            <div
                className={isSafari ? 'safari' : ""}
                onClick={clickHandler}
                dangerouslySetInnerHTML={{__html: data}}
            />
        </div>
    );
};

export default Page;