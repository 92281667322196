import React, {useLayoutEffect} from 'react';

type PageNotFoundProps = {}
const PageNotFound: React.FunctionComponent<PageNotFoundProps> = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>Die Seite konnte nicht gefunden werden (404)</div>
    );
};

export default PageNotFound;