export interface Image {
    publicUrl: string,
    title: string,
    alternative: string,
    copyright: string,
    description: string,
    creator: string,
    contentCreationDate: number,
    processedImageUrl: string,
    mime: string,
    extension: string
}

export interface Topic {
    image: Image,
    title: string,
    description: string,
    text: string,
    uid: number
}

export interface Difficulty {
    rankOneTextDefault: string,
    rankThreeTextDefault: string,
    rankTwoTextDefault: string,
    title: string,
    uid: number
}

export enum AnswerStatus {
    normal,
    correct,
    wrong,
    info
}

export interface AnswerObject {
    correct: boolean,
    isCorrect: boolean,
    text: string,
    uid: number
}

export interface ExplanationObject {
    title: string | undefined,
    media: Media | null,
    text: string | undefined
}

export interface QuestionObject {
    answers: AnswerObject[],
    explanation: ExplanationObject | null,
    media: Media | null,
    text: string,
    uid: number
}

export interface Media {
    publicUrl: string,
    title: string,
    alternative: string,
    copyright: string,
    description: string,
    creator: string,
    contentCreationDate: number,
    processedImageUrl: string,
    mime: string,
    extension: string
}

export interface QuizObject {
    topic: Topic,
    difficulty: Difficulty,
    maximum: number,
    participantsCounter: number,
    questions: QuestionObject[],
    rankOneText: string,
    rankThreeText: string,
    rankTwoText: string,
    requirementRankThree: number,
    requirementRankTwo: number,
    uid: number
}

export interface NotAllowedQuizObject {
    topic: Topic,
    maximum: number,
    participantsCounter: number,
    uid: number
}