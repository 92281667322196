import React, {useLayoutEffect} from 'react';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";
import {makeStyles, Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import {useData} from "../layout";

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    buttonWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: spacing(4)
    },
    button: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white',
        margin: '20px auto'
    },
    button2: {
        border: '1px solid black',
        color: 'black',
        backgroundColor: 'white',
        margin: '0 0 20px'
    }
}));

const Topic = () => {
    const classes = useStyles();
    const history = useHistory();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {selectedQuiz} = useData();
    const {isNotAllowed} = useData();

    const goBack = () => {
        localStorage.setItem('currentPage', '/topics');
        history.push('/topics');
    };

    const chooseQuiz = () => {
        localStorage.setItem('currentPage', '/quiz');
        history.push('/quiz');
    };

    return (
        <div>
            {(selectedQuiz !== undefined && selectedQuiz) && (
                <div>
                    <h3>{selectedQuiz.topic.title}</h3>
                    {selectedQuiz.topic.image &&
                    <div className={"image-caption-wrapper"}>
                        <img style={{width: "100%", display: "block"}}
                             src={selectedQuiz.topic.image.processedImageUrl}
                             alt={selectedQuiz.topic.image.alternative}/>
                        <span className="image-copyright">
                             <p>{selectedQuiz.topic.image.description}</p>
                            <p>{selectedQuiz.topic.image.copyright}</p>
                        </span>
                    </div>
                    }
                    <div>
                        {isNotAllowed ? (
                            <p style={{color: 'red'}}>Dieses Quiz ist nur im Museum spielbar.</p>
                        ) : (
                            <Button style={{margin: '2em 0 0'}} fullWidth={true} onClick={() => {
                                chooseQuiz()
                            }} variant="contained" color="primary" className={classes.button2}>
                                Quiz starten
                            </Button>
                        )}
                    </div>
                    <p>{selectedQuiz.topic.description}</p>
                </div>
            )}
            <div className={classes.buttonWrapper}>
                <Button onClick={() => goBack()} variant="contained" className={classes.button}>Zurück</Button>
            </div>
        </div>
    );
};

export default Topic;
